/* You can add global styles to this file, and also import other style files */
// @import "./group-styles.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-text-primary: rgb(15 23 42);
    --color-text-base: rgb(75 85 99);
    --color-text-muted: rgb(107 114 128);
    --color-text-extramuted: rgb(156 163 175);
    --color-inverted: rgb(255 255 255);
    --color-primary: rgb(29 78 216);

    --color-fill-base: rgb(243 244 246);
    --color-fill-muted: rgb(249 250 251);

    --min-height-with-title: calc(100vh - 120px);
    --min-height-base: calc(100vh - 90px);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "DM Sans", sans-serif;
  margin: 0;
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}

.box {
  @apply px-8;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .box {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .box {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .box {
    width: 1170px;
  }
}

.lookup-list {
  height: calc(100vh - 250px);
}

.list-height {
  height: calc(100vh - (280px));
}

// Taiga overrides
[tuiWrapper][data-appearance="textfield"] {
  box-shadow: none !important;
}

code {
  font-family: inherit;
  background: rgba(174, 204, 252, 0.5);
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

// scollbar

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  overflow: overlay;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  border: 0px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.login-bg {
  background-image: url("./assets/images/login-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Add the blur effect */
  filter: blur(10px);
  -webkit-filter: blur(10px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
